
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, InputGroup, Modal, Nav, Spinner, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ChooseExchange, TokenSelectAndSetValueRow, initProvider } from "./liquidityBox";
import { NETWORK_LIST } from "../../../utils/networks";
import { useSelector } from "react-redux";
import { GetSwapRouterList } from "../../../api/swap";
import { Multicall3, NewMulticall3Contract } from "../../../utils/multicall3";
import { dexAbi, swapFuncAbi, tokenAbi } from "../../../utils/abi";
import { BigNumber, ethers } from "ethers";
import Logo from "../../components/logo";
import { convertNumberToString, eventBus, findTokenFromCommonTokens, findWETHTokenFromCommonTokens } from "../../../utils/tools";
import { toast } from "react-toastify";



const HandleSwapModal = (props) => {
    const [currentStep, setCurrentStep] = useState(0) // 1: 授权 2: 兑换
    const [errMsg, setErrMsg] = useState('')
    const [approveLoading, setApproveLoading] = useState(false) // 是否正在授权
    const [swapLoading, setSwapLoading] = useState(false) // 是否正在兑换

    const [confirmSwapTx, setConfirmSwapTx] = useState(false) // 是否已经确认兑换交易
    const [confirmApproveTx, setConfirmApproveTx] = useState(false) // 是否已经确认授权交易
    const [token0, setToken0] = useState(null)
    const [token1, setToken1] = useState(null)
    const [token0Input, setToken0Input] = useState('')
    const [token1Input, setToken1Input] = useState('')
    const [route, setRoute] = useState(null)
    const token0DecimalsRef = useRef(18)
    const token1DecimalsRef = useRef(18)
    const account = useSelector(state => state.account)
    useEffect(() => {
        setErrMsg('')
        setApproveLoading(false)
        setSwapLoading(false)
        setToken0(props.token0)
        setToken1(props.token1)
        setToken0Input(props.token0Input)
        setToken1Input(props.token1Input)
        setRoute(props.route)
    }, [props])

    useEffect(() => {
        if (!props.show) {
            setCurrentStep(0)
            return
        }
        setCurrentStep(1)
    }, [props.show])

    useEffect(() => {
        if (currentStep === 1) {
            approve()
        }
        if (currentStep === 2) {
            handleSwap()
        }
    }, [currentStep])

    const approve = async () => {
        setErrMsg('')
        setConfirmApproveTx(false)
        // 获取当前授权数量
        // 如果授权数量小于兑换数量, 则需要授权
        // 执行授权函数
        // 等待授权完成
        // 切换到兑换

        try {
            if (token0.isWETH) {
                setCurrentStep(2) // 无需授权, 直接兑换
                return
            }
            const provider = initProvider()
            const n = new Multicall3(token0.chain_id, provider)
            const n1 = NewMulticall3Contract(token0.address, tokenAbi)
            const n2 = NewMulticall3Contract(token1.address, tokenAbi)
            const r = await n.call([
                n1.encode("allowance", [account, route.router]),
                n1.encode("decimals", []),
                n2.encode("decimals", []),
            ])
            const token0Decimals = parseInt(r[1].result[0])
            const token1Decimals = parseInt(r[2].result[0])

            token0DecimalsRef.current = token0Decimals
            token1DecimalsRef.current = token1Decimals

            const token0InputValue = ethers.utils.parseUnits(token0Input + '', parseInt(token0Decimals))
            const allowance = r[0].result[0]
            if (allowance.lt(token0InputValue)) {

                setApproveLoading(true)
                setConfirmApproveTx(false)
                const _token = new ethers.Contract(token0.address, tokenAbi, provider.getSigner())
                const tx = await _token.approve(route.router, token0InputValue)
                setConfirmApproveTx(true) // 已确认, 等待交易完成
                await tx.wait()
                setApproveLoading(false)
                approve()
                return
            }
            setCurrentStep(2)
        } catch (e) {
            setErrMsg(e.reason || JSON.stringify(e))
        }
        setApproveLoading(false)
    }

    const handleSwap = async () => {
        setErrMsg('')
        setConfirmSwapTx(false)
        const provider = initProvider()
        const min = route.tokenAmountsOut * (1 - ((route.slipage + 1) / 100))
        const outMin = ethers.utils.parseUnits(min.toFixed(token1.decimals - 1), token1DecimalsRef.current)
        // 调用router
        const _router = new ethers.Contract(route.router, dexAbi, provider.getSigner())
        const deadline = ethers.utils.parseUnits((Math.floor(Date.now() / 1000) + 60 * 10) + '', 0)
        const token0InputValue = ethers.utils.parseUnits(token0Input + '', parseInt(token0DecimalsRef.current))
        let tx = null
        setSwapLoading(true)
        try {
            setConfirmSwapTx(false)
            if (token0.isWETH) {
                tx = await _router.swapExactETHForTokens(outMin, route.route, account, deadline, {
                    value: token0InputValue,
                })
            } else {
                if (token1.isWETH) {
                    tx = await _router.swapExactTokensForETH(token0InputValue, outMin, route.route, account, deadline)
                } else {
                    tx = await _router.swapExactTokensForTokens(token0InputValue, outMin, route.route, account, deadline)
                }
            }

            if (!tx) {
                setErrMsg(t('交易失败'))
                return
            }
            setConfirmSwapTx(true) // 已确认, 等待交易完成
            await tx.wait()
            setConfirmSwapTx(false)
            toast.success(t('交易成功'))
            props.ok && props.ok()
        } catch (e) {
            setErrMsg(e.reason || JSON.stringify(e))
        }
        setSwapLoading(false)
    }

    const { t } = useTranslation()
    return <Modal centered contentClassName="search-modal" show={props.show} onHide={() => {
        props.handleClose && props.handleClose()
    }} >
        <Modal.Body>
            <div>
                <div className="login-header">
                    <div style={{ 'width': '100%' }}>
                        <div className="stake-modal-bar">
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 1 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">1</div>
                                <div className="stake-modal-bar-item-title"> {t('授权')}</div>
                            </div>
                            <div className={"stake-modal-bar-item-solid " + (currentStep > 1 ? 'stake-modal-bar-item-solid-success' : '')}></div>
                            <div className={"stake-modal-bar-item-box " + (currentStep >= 2 ? 'stake-modal-bar-item-box-success' : '')}>
                                <div className="stake-modal-bar-item">2</div>
                                <div className="stake-modal-bar-item-title"> {t('兑换')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {errMsg && <div className="mt-4">
                        <Alert variant="danger">
                            <div>
                                {errMsg}
                            </div>
                        </Alert>
                    </div>}

                    {currentStep === 1 && <div className="full-width mt-4">
                        {approveLoading && <div className="div-col-center">
                            <Spinner />
                        </div>}
                        <div className="div-col-center mt-4">
                            {approveLoading ? <div className="">
                                {confirmApproveTx ? t('等待授权交易完成...') : t('请确认授权...')}
                            </div> : <div className="div-row-center">
                                <div onClick={() => {
                                    approve()
                                }} className="btn btn-primary text-light">
                                    {t('授权')}
                                </div>
                                <div style={{ marginLeft: '5px' }} onClick={() => {
                                    props.onHide && props.onHide()
                                }} className="btn btn-danger text-light">
                                    {t('关闭')}
                                </div>
                            </div>}
                        </div>
                    </div>}

                    {currentStep === 2 && <div className="full-width mt-4">
                        {swapLoading && <div className="div-col-center">
                            <Spinner />
                        </div>}
                        <div className="div-col-center mt-4">
                            {swapLoading ? <div >
                                {confirmSwapTx ? '等待兑换交易完成...' : '确认交易中...'}
                            </div> : <div className="div-row-center">
                                <div onClick={() => {
                                    handleSwap()
                                }} className="btn btn-primary text-light">
                                    {t('确认兑换')}
                                </div>
                                <div style={{ marginLeft: '5px' }} onClick={() => {
                                    props.onHide && props.onHide()
                                }} className="btn btn-danger text-light">
                                    {t('关闭')}
                                </div>
                            </div>
                            }
                        </div>
                    </div>}
                </div>
            </div>
        </Modal.Body>

    </Modal>
}

export const SwapMainBox = (props) => {

    const [selectedSwap, setSelectedSwap] = useState(null) // [pancake, bakery, julswap
    const [token0, setToken0] = useState(null)
    const [token1, setToken1] = useState(null)
    const [errMsg, setErrMsg] = useState('')
    const [swapList, setSwapList] = useState([]) // [pancake, bakery, julswap
    const [token0Input, setToken0Input] = useState('')
    const [token1Input, setToken1Input] = useState('')
    const [autoSlipage, setAutoSlipage] = useState(true) // 自动滑点
    const [selectedSlipage, setSelectedSlipage] = useState(0) // 当前滑点

    const [routeList, setRouteList] = useState([]) // 当前可用的路由列表
    const [selectedRoute, setSelectedRoute] = useState(null) // 当前选择的路由
    const [showSlipageModal, setShowSlipageModal] = useState(false) // 设置滑点的modal
    const [showSwapModal, setShowSwapModal] = useState(false)

    const [loading, setLoading] = useState(false) // 是否正在询价
    const [swapLoading, setSwapLoading] = useState(false) // 是否正在兑换

    const account = useSelector(state => state.account)
    const chainId = useSelector(state => state.chainId)
    const lastInputType = useRef('token0')

    const appendSwap = useRef(null)


    useEffect(() => {
        GetSwapRouterList({}).then(({ data }) => {
            if (data.code === 200) {
                let list = data.data || []
                if (appendSwap.current) {
                    list.push(appendSwap.current)
                }

                setSwapList(list)
            }
        })
    }, [])

    const checkRouterTimer = useRef(null)
    const token0InputRef = useRef('')
    const token1InputRef = useRef('')


    const swapToken0AndToken1 = () => {
        const tmpInput = token0Input
        token0InputRef.current = token1Input
        token1InputRef.current = tmpInput
        setToken0Input(token1Input)
        setToken1Input(tmpInput)

        const tmpToken = token0
        setToken0(token1)
        setToken1(tmpToken)

    }

    useEffect(() => {
        if (props.tokenInfo?.chain_id && props.pair?.swap_factory) {
            setSelectedSwap({
                chain_id: props.tokenInfo?.chain_id,
                factory: props.pair?.swap_factory
            })
        }


        if (props.defaultSwapAction === 'buy') {
            if (props.tokenInfo) {
                // 寻找第一个isWETH为true的代币
                const n = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(props.tokenInfo.chain_id))
                if (n && n.commonTokens && n.commonTokens.length > 0) {
                    const weth = n.commonTokens.find(item => item.isWETH)
                    setToken0({
                        ...weth,
                        chain_id: parseInt(props.tokenInfo.chain_id),
                    })
                }
                setToken1({
                    ...props.tokenInfo,
                    isWETH: false
                })
            }
        }
        if (props.defaultSwapAction === 'sell') {
            if (props.tokenInfo) {
                // 寻找第一个isWETH为true的代币
                const n = NETWORK_LIST.find(item => parseInt(item.chainId) === parseInt(props.tokenInfo.chain_id))
                if (n && n.commonTokens && n.commonTokens.length > 0) {
                    const weth = n.commonTokens.find(item => item.isWETH)
                    setToken1({
                        ...weth,
                        chain_id: parseInt(props.tokenInfo.chain_id),
                    })
                }
                setToken0({
                    ...props.tokenInfo,
                    isWETH: false
                })
            }
        }


    }, [props])


    useEffect(() => {

        // 添加交易所
        if (props.urlParams?.chain_id && props.urlParams?.factory) {
            const d = {
                chain_id: parseInt(props.urlParams?.chain_id),
                factory: props.urlParams?.factory,
                router: props.urlParams?.router,
                value: props.urlParams?.router,
                name: props.urlParams?.name,
                logo: '',
                version: 'v2'
            }
            appendSwap.current = d
            setSelectedSwap(d)
        }

        if (props.urlParams?.chain_id && props.urlParams?.token) {
            findTokenFromCommonTokens(parseInt(props.urlParams?.chain_id), props.urlParams?.token)
                .then(tokenInfo => {
                    const weth = findWETHTokenFromCommonTokens(parseInt(props.urlParams?.chain_id))
                    if (props.urlParams.action === 'buy') {
                        setToken1(tokenInfo)
                        setToken0(weth)
                    } else {
                        setToken0(tokenInfo)
                        setToken1(weth)
                    }
                })

        }

    }, [props.urlParams, account])

    useEffect(() => {
        setErrMsg('')
        if (checkRouterTimer.current) {
            clearTimeout(checkRouterTimer.current)
            checkRouterTimer.current = null
        }
        // 如果修改的是token0Input, 则变更token1Input后不应该触发


        if (lastInputType.current === 'token0' && token1Input !== token1InputRef.current) {
            return
        }
        if (lastInputType.current === 'token1' && token0Input !== token0InputRef.current) {
            return
        }
        checkRouterTimer.current = setTimeout(() => {
            checkRouter().catch(e => {
                console.log(e)
                setRouteList([])
                setSelectedRoute(null)
                setLoading(false)
            })
        }, 500);
    }, [token0, token1, token0Input, token1Input])

    const checkRouter = async () => {
        if (!token0?.symbol || !token1?.symbol) {
            return
        }
        if (token0.chain_id !== token1.chain_id) {
            setErrMsg(t('暂不支持跨链兑换, 请选择相同公链的币种'))
            return
        }
        if (!token0Input && !token1Input) {
            return
        }

        if (lastInputType.current === 'token0') {
            if (!token0Input) {
                return
            }
            if (parseFloat(token0Input) === 0) {
                return
            }

        }
        if (lastInputType.current === 'token1') {
            if (!token1Input) {
                return
            }
            if (parseFloat(token1Input) === 0) {
                return
            }
        }

        if (token0.chain_id !== chainId) {
            const n = NETWORK_LIST.find(item => item.chainId === token0.chain_id)
            if (n) {
                setErrMsg(`请将钱包切换到{x}网络`.replace('{x}', n.name))
                return
            }
        }

        const routers = swapList.filter(item => item.chain_id === token0.chain_id)
        if (routers.length === 0) {
            setErrMsg(t('流动性不足, 请稍后再试'))
            return
        }

        const n = NETWORK_LIST.find(item => item.chainId === token0.chain_id)
        if (!n) {
            setErrMsg(t('暂不支持该公链'))
            return
        }

        setLoading(true)
        // 请求数据
        const provider = initProvider()
        const p = new Multicall3(chainId, provider)

        // 获取两个代币的精度
        let token0Decimals = 18
        let token1Decimals = 18
        let c1 = []
        if (token0.address) {
            const n2 = NewMulticall3Contract(token0.address, tokenAbi)
            c1.push(n2.encode("decimals"))
        }
        if (token1.address) {
            const n2 = NewMulticall3Contract(token1.address, tokenAbi)
            c1.push(n2.encode("decimals"))
        }
        const r1 = await p.call(c1)
        if (r1 && r1.length > 0) {
            let ind = 0
            if (token0.address) {
                token0Decimals = parseInt(r1[ind].result[0])
                ind++
            }
            if (token1.address) {
                token1Decimals = parseInt(r1[ind].result[0])
                ind++
            }
        }

        // 读取可用的路由, 如果没有, 则提示没有路由
        // 如果有, 则提示路由
        // tokenA tokenB 拼接可用路由: tokenA->tokenB tokenA->wbnb->tokenB tokenA->wbnb->commonTokens->tokenB tokenA->commonTokens->tokenB
        // 根据路径获取getAmountsOut 获取能得到的tokenB最多的那个
        // 进行模拟交易, 获取最终的结果
        let list = []



        const uniqueList = (arr) => {
            let r = []
            // 去重复
            let m = {}
            arr.forEach(item => {
                if (!item) return
                const x = item.toLowerCase()
                if (!m[x]) {
                    m[x] = true
                    r.push(x)
                }
            })

            return r
        }

        const rMap = {}
        const insertToList = (sawpInfo, routerList, commonToken, weth) => {
            const swapRouter = sawpInfo.router
            const r = uniqueList(routerList)
            const key = `${swapRouter}-${r.join('-')}`
            if (!rMap[key]) {
                rMap[key] = true
                // buyPath sellPath token地址 
                // 买入: 以bnb开头,或bnb+commonToken开头, 以token1结尾
                // 卖出: 以token1开头, 以bnb结尾, 就是买入交易的反向路径
                // 如果以weth结尾, 则默认为卖出, 买入直接做为卖出的反向路径
                let endWithWeth = false
                let buyPath = []
                let sellPath = []
                if (r[r.length - 1] === weth) {
                    endWithWeth = true
                    sellPath.push(uniqueList([...r]))
                    buyPath.push(uniqueList([...r].reverse()))
                } else {
                    if (r[0] === weth) {
                        const b = uniqueList([...r])
                        const s = uniqueList([...b].reverse())
                        buyPath.push(b)
                        sellPath.push(s)
                    } else {
                        const b = uniqueList([weth, ...r])
                        const s = uniqueList([...b].reverse())
                        buyPath.push(b)
                        sellPath.push(s)
                    }
                    if (commonToken) {
                        if (r[0] === weth) {
                            const b = uniqueList([weth, commonToken, ...r])
                            const s = uniqueList([...b].reverse())
                            buyPath.push(b)
                            sellPath.push(s)
                        } else {
                            const b = uniqueList([weth, commonToken, ...r])
                            const s = uniqueList([...b].reverse())
                            buyPath.push(b)
                            sellPath.push(s)
                        }
                    }

                }
                list.push({
                    router: swapRouter,
                    list: routerList,
                    endWithWeth,
                    buyPath,
                    sellPath,
                    swap: sawpInfo

                })
            }

        }

        let tokenMap = {}
        routers.forEach(item => {
            if (n.commonTokens && n.commonTokens.length > 0) {
                n.commonTokens.forEach(commonToken => {
                    const token0Address = token0.isWETH ? item.weth : token0.address
                    const token1Address = token1.isWETH ? item.weth : token1.address
                    tokenMap[token0Address.toLowerCase()] = token0.symbol
                    tokenMap[token1Address.toLowerCase()] = token1.symbol
                    if (commonToken.address) {
                        tokenMap[commonToken.address.toLowerCase()] = commonToken.symbol
                    }

                    insertToList(item, [token0Address, token1Address], commonToken.address, item.weth)
                    insertToList(item, [token0Address, commonToken.address, token1Address], commonToken.address, item.weth)
                    insertToList(item, [token0Address, item.weth, token1Address], commonToken.address, item.weth)
                    insertToList(item, [token0Address, item.weth, commonToken.address, token1Address], commonToken.address, item.weth)
                    insertToList(item, [token0Address, commonToken.address, item.weth, token1Address], commonToken.address, item.weth)
                })

            }
        })



        const net = NETWORK_LIST.find(item => item.chainId === token0.chain_id)


        let calls = []
        list.forEach(item => {
            const n1 = NewMulticall3Contract(item.router, dexAbi)
            const list = [...item.list]
            let amount = 0
            if (lastInputType.current === 'token1') {
                list.reverse()
                amount = ethers.utils.parseUnits(token1Input + '', token1Decimals)
            } else {
                amount = ethers.utils.parseUnits(token0Input + '', token0Decimals)
            }
            calls.push(n1.encode("getAmountsOut", [amount, list]))
        })
        let r = []

        try {
            r = await p.call(calls)
        } catch (e) {
            console.log(e)
            setSelectedRoute(null)
            setLoading(false)
            return
        }

        // 筛选出其中正确的结果和路由
        let ind = 0
        let list1 = []
        r.forEach((item, rInd) => {
            if (item.result && item.result.length > 0) {
                const tokenAmountsOut = item.result[0][item.result[0].length - 1].toString() / Math.pow(10, token1Decimals)
                if (tokenAmountsOut > 0) {
                    list1.push({
                        tokenAmountsOut,
                        result: item.result,
                        ...list[ind]
                    })
                }
            }
            ind += 1
        })


        const _func = new ethers.Contract(net.swap_func, swapFuncAbi, provider)


        // let calls = []
        let _tokens = []
        let _routers = []
        let _buyPaths = []
        let _sellPaths = []

        list1.forEach(item => {

            item.buyPath.forEach((buyPathItem, ind) => {
                _tokens.push(buyPathItem[buyPathItem.length - 1])
                _routers.push(item.router)
                _buyPaths.push([...buyPathItem])
                _sellPaths.push([...item.sellPath[ind]])
            })
        })

        const res = await _func.callStatic.mock(_tokens, _routers, _buyPaths, _sellPaths, {
            value: ethers.utils.parseEther('0.00000001')
        })

        // 通过getAmountsOut获取最大的输出来选择路径
        // 然后寻找路径对应的滑点、价格影响、预计手续费、交易路由

        // 整理一下: 获取每一条路径的滑点、预计获得的代币数量、价格影响、预计手续费、交易路由
        let pathList = []
        list1.forEach((item, listInd) => {

            // 处理buyPath,用来获取滑点,获取模拟交易中, 滑点最小的那个
            let ind = 0
            let currentSlipage = 0
            let currentGas = 0
            let pairBalance = 0
            item.buyPath.forEach((buyPathItem) => {
                // 获取信息
                const resItem = res[ind]
                const buyGas = parseInt(resItem.buyGas.toString())
                const sellGas = parseInt(resItem.sellGas.toString())
                const preditBuy = parseInt(resItem.preditBuy.toString())
                const preditSell = parseInt(resItem.preditSell.toString())
                const factBuy = parseInt(resItem.factBuy.toString())
                const factSell = parseInt(resItem.factSell.toString())
                const pb = parseInt(resItem.pairTokenBalance.toString()) / Math.pow(10, token1Decimals)
                if (item.endWithWeth) { // 以eth结尾, 则看卖出交易的滑点
                    const slipage = (preditSell - factSell) / preditSell
                    const gas = sellGas / 1e9
                    currentGas = gas
                    pairBalance = pb
                    if (slipage > currentSlipage) {
                        currentSlipage = slipage
                    }

                } else { // 看买入交易的滑点
                    const slipage = (preditBuy - factBuy) / preditBuy
                    const gas = buyGas / 1e9
                    currentGas = gas
                    pairBalance = pb
                    if (slipage > currentSlipage) {
                        currentSlipage = slipage
                    }

                }
                ind += 1
            })




            const tokenAmountsOut = item.result[0][item.result[0].length - 1].toString() / Math.pow(10, token1Decimals)

            let p = {
                swap: item.swap,
                router: item.router,
                slipage: currentSlipage * 100,
                gas: currentGas,
                netSymbol: net.symbol,
                tokenAmountsOut: tokenAmountsOut,
                priceImpact: tokenAmountsOut / pairBalance * 100,
                fee: 0,
                route: item.list,
                routeNames: item.list.map(subItem => {
                    return tokenMap[subItem.toLowerCase()] || subItem
                })
            }
            pathList.push(p)
        })


        if (pathList.length > 0) {
            // 将pathlist按照tokenAmountsOut从大到小排序
            pathList.sort((a, b) => {
                return b.tokenAmountsOut - a.tokenAmountsOut
            })
            setRouteList(pathList)
            setSelectedRoute(pathList[0])
            const sSwap = pathList[0]?.swap
            if (sSwap) {
                setSelectedSwap({
                    ...sSwap,
                    value: sSwap.router
                })

            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (autoSlipage) {
            setSelectedSlipage((((selectedRoute?.slipage || 0) + 1)).toFixed(2)) // 滑点加1
        }
        if (selectedRoute) {
            if (lastInputType.current === 'token0') {
                setToken1Input(convertNumberToString(selectedRoute.tokenAmountsOut) + '')
            }
            if (lastInputType.current === 'token1') {
                setToken0Input(convertNumberToString(selectedRoute.tokenAmountsOut) + '')
            }
        }

    }, [selectedRoute, autoSlipage])

    const [tmpSlipage, setTmpSlipage] = useState('') // 临时滑点

    const handleSwap = async () => {
        // 确认兑换
        if (!selectedRoute) {
            setErrMsg(t('暂无可用路由'))
            return
        }
        if (!token0Input || !token1Input) {
            setErrMsg(t('请输入兑换数量'))
            return
        }
        if (parseFloat(token0Input) === 0 || parseFloat(token1Input) === 0) {
            setErrMsg(t('请输入兑换数量'))
            return
        }
        if (token0.address.toLowerCase() === token1.address.toLowerCase()) {
            setErrMsg(t('不能兑换相同的代币'))
            return
        }
        if (!token0 || !token1) {
            setErrMsg(t('请选择代币'))
            return
        }
        if (token0.chain_id !== token1.chain_id) {
            setErrMsg(t('暂不支持跨链兑换, 请选择相同公链的币种'))
            return
        }
        if (token0.chain_id !== chainId) {
            const n = NETWORK_LIST.find(item => item.chainId === token0.chain_id)
            if (n) {
                setErrMsg(`请将钱包切换到{x}网络`.replace('{x}', n.name))
                return
            }
        }

        setSwapLoading(true)

        // 判断余额是否充足
        const provider = initProvider()
        const n = new Multicall3(chainId, provider)
        let list = []
        if (token0.isWETH) {
            list.push(n.getEthBalance(account))
        } else {
            const n1 = NewMulticall3Contract(token0.address, tokenAbi)
            list.push(n1.encode("balanceOf", [account]))
        }
        if (token1.isWETH) {
            list.push(n.getEthBalance(account))
        } else {
            const n1 = NewMulticall3Contract(token1.address, tokenAbi)
            list.push(n1.encode("balanceOf", [account]))
        }
        try {
            const r = await n.call(list)
            const token0Balance = r[0].result[0]
            const token1Balance = r[1].result[0]
            const token0InputValue = ethers.utils.parseUnits(token0Input + '', token0.decimals)
            const token1InputValue = ethers.utils.parseUnits(token1Input + '', token1.decimals)
            if (token0InputValue.gt(token0Balance)) {
                setSwapLoading(false)
                setErrMsg(t(`{name}余额不足`).replace('{name}', token0.symbol))
                return

            }
            setShowSwapModal(true)
        } catch (e) {

        }
        setSwapLoading(false)


    }

    const { t } = useTranslation()
    return <div className="full-width div-col-center row">
        <HandleSwapModal
            token0={token0}
            token1={token1}
            token0Input={token0Input}
            token1Input={token1Input}
            show={showSwapModal}
            route={selectedRoute}
            onHide={() => {
                setShowSwapModal(false)
            }}
            ok={() => {
                checkRouter()
                eventBus.emit('refreshBalance')
                setShowSwapModal(false)
            }}
        />
        <Modal centered contentClassName="search-modal" show={showSlipageModal} onHide={() => {
            setShowSlipageModal(false)
        }} >
            <Modal.Body>
                <div>
                    <div className="login-header">{t('设置滑点')}</div>
                    <div className="mt-4">
                        <Form>
                            <InputGroup>
                                <Form.Control value={tmpSlipage} className="form-control-shadow-outline-none"

                                    onChange={e => {
                                        const inputValue = e.target.value;
                                        // 根据正则过滤, 只要数字
                                        const numericValue = inputValue.replace(/[^0-9.]/g, ''); // 仅保留数字和小数点
                                        setTmpSlipage(numericValue)
                                    }} />
                                <InputGroup.Text >
                                    %
                                </InputGroup.Text>
                            </InputGroup>
                            <div>
                                <Button onClick={e => {
                                    setSelectedSlipage(tmpSlipage)
                                    setTmpSlipage('')
                                    setShowSlipageModal(false)
                                }} className="mt-4 text-light" variant="primary" size="sm" >{t('确认')}</Button>
                                <Button onClick={e => {
                                    setShowSlipageModal(false)
                                }} className="mt-4 text-light" style={{ marginLeft: '5px' }} size="sm" variant="danger" >{t('关闭')}</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <div >
            <div>
                <ChooseExchange
                    chainId={selectedSwap?.chain_id}
                    router={selectedSwap?.value}
                    factory={selectedSwap?.factory}
                    name={selectedSwap?.name}
                    fullWidth={props.fullWidth} onSwapChange={swap => {
                        setSelectedSwap(swap)
                        const n = swapList.find(item => item.chain_id === swap.chain_id && item.factory === swap.factory && item.router === swap.value)
                        if (!n) {
                            const s = {
                                ...swap,
                                logo: '',
                                router: swap.value,
                                version: 'v2',
                                name: swap.label
                            }
                            appendSwap.current = s
                            setSwapList([...swapList, s])

                        }
                    }} />
            </div>
            <div className="row div-col-center mt-2">
                <div className={props.fullWidth ? `col-12 mt-2` : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <div style={{ borderBottom: '0.5px solid #a1a0a7', marginBottom: '1rem', opacity: '0.4' }} >  </div>
                    {/* <div className="add-lp-token-select-box "> */}
                    <div>
                        <TokenSelectAndSetValueRow onSelect={token => {
                            // lastInputType.current = 'token0'
                            setToken0(token)
                        }} token={token0} value={token0Input} onInput={e => {
                            lastInputType.current = 'token0'
                            token0InputRef.current = e
                            token1InputRef.current = token1Input
                            setToken0Input(e)
                        }} chains={selectedSwap?.chain_id ? [selectedSwap.chain_id] : NETWORK_LIST.map(item => item.chainId)} />
                        <div style={{ marginBottom: '0rem', marginTop: '1rem' }} className="div-col-center">
                            <i onClick={() => {
                                swapToken0AndToken1()
                            }} style={{ fontSize: '25px', cursor: 'pointer' }} className="mdi mdi-swap-vertical"></i>
                        </div>
                        <TokenSelectAndSetValueRow token={token1} onSelect={token => {
                            // lastInputType.current = 'token1'
                            setToken1(token)
                        }} value={token1Input} onInput={e => {
                            lastInputType.current = 'token1'
                            token1InputRef.current = e
                            token0InputRef.current = token0Input
                            setToken1Input(e)
                        }} chains={selectedSwap?.chain_id ? [selectedSwap.chain_id] : NETWORK_LIST.map(item => item.chainId)} />
                    </div>
                    <div style={{ borderBottom: '0.5px solid #a1a0a7', marginBottom: '1rem', marginTop: '1rem', opacity: '0.4' }} >  </div>

                </div>

            </div>
            <div className="row div-col-center mt-2">
                {selectedRoute && selectedRoute.router && <div className={props.fullWidth ? `col-12 mt-2` : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            <div className="div-row-center">
                                <Form.Check checked={autoSlipage} className="div-row-center" style={{ height: '100%' }} onChange={e => {
                                    setAutoSlipage(e.target.checked)
                                }}>
                                </Form.Check>
                                <span style={{ marginLeft: '5px' }}>{t('自动滑点')}</span>
                            </div>
                        </div> 
                        {autoSlipage && <div className="liq-box-right">
                            {selectedSlipage}%
                        </div>}
                        {!autoSlipage && <div>
                            <div onClick={() => {
                                // showSlipageModal()
                                setShowSlipageModal(true)
                            }} className="div-col-center" style={{ minWidth: '50px', borderBottom: '1px dashed #999999', cursor: 'pointer' }}>
                                {selectedSlipage}%
                            </div>
                        </div>}
                    </div>
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {t('最小接收')}
                        </div>
                        <div className="liq-box-right">
                            {(selectedRoute.tokenAmountsOut * (1 - ((selectedRoute.slipage + 1) / 100))).toFixed(6)} {token1.symbol}
                        </div>
                    </div>
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {t('价格影响')}
                        </div>
                        <div className="liq-box-right">
                            {selectedRoute.priceImpact.toFixed(2)}%
                        </div>
                    </div>
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {t('预计手续费')}
                        </div>
                        <div className="liq-box-right">
                            {selectedRoute.gas} {selectedRoute.netSymbol}
                        </div>
                    </div>
                    <div className="full-width div-row-center liq-box">
                        <div className="liq-box-left">
                            {t('交易路由')}
                        </div>
                        <div style={{ justifyContent: 'flex-end' }} className="liq-box-right div-row-center">
                            <span style={{ marginRight: '8px' }}>  <Logo size="1.4" logo={selectedRoute.swap.logo} /> </span>    {selectedRoute.routeNames.map((item, ind) => {
                                return <>
                                    {ind > 0 && <span key={'rn-kd-' + item + 'ind' + ind}>
                                        {ind > 0 && <i className="mdi mdi-arrow-right"></i>}
                                    </span>}
                                    <span key={'rn-k-' + item + 'ind' + ind}>
                                        {item}
                                    </span>
                                </>
                            })}
                            {/* {selectedRoute.routeNames.join(' > ')} */}
                            {/* BNB <i className="mdi mdi-arrow-right"></i> USDT  <i className="mdi mdi-arrow-right"></i> Token */}
                        </div>
                    </div>
                </div>}

                <div className={props.fullWidth ? `col-12 mt-2` : `col-12 col-md-8 col-lg-6 col-xxl-6 col-xl-6 col-xs-10 mt-2`} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    {errMsg && <div className="full-width">
                        <Alert variant="danger">
                            {errMsg}
                        </Alert>
                    </div>}
                    {chainId > 0 && <div className="full-width div-col-center mt-2">
                        {!loading && !swapLoading && <Button onClick={handleSwap} type="primary" className="text-light">{t('确认兑换')}</Button>}
                        {!loading && swapLoading && <Button onClick={handleSwap} type="primary" disabled className="text-light"> <Spinner style={{ width: '12px', height: '12px' }} /> 兑换中...</Button>}
                        {loading && <Button type="primary" className="text-light" disabled> <Spinner style={{ width: '12px', height: '12px' }} /> {t('询价中...')}</Button>}
                    </div>}
                    {chainId === 0 && <div className="full-width div-col-center mt-2">
                        <Button onClick={() => {
                            eventBus.emit('request_login')
                        }} type="primary" className="text-light">{t('请先登录')}</Button>
                    </div>}

                </div>
            </div>
        </div>

    </div>

}